var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-new pd2",staticStyle:{"padding-bottom":"300px"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.UserData.usertype
          == 'member')?_c('router-link',{attrs:{"to":{ name: 'tanghuay-home' }}},[_c('button',{staticClass:"bnt-home"},[_c('i',{staticClass:"fas fa-home"})])]):_vm._e(),_vm._m(0)],1),_c('div',{staticClass:"mb-2 mt-1"},[_c('router-link',{attrs:{"to":{ name: 'tanghuay-list' }}},[_c('span',{staticClass:"text-white mt-2"},[_c('i',{staticClass:"far fa-arrow-circle-left"}),_vm._v(" ย้อนกลับ")])]),_c('div',{staticClass:"text-center bg-pink block mt-2"},[_c('h4',{staticClass:"text-white bg-text-card mx-auto"},[_vm._v(" เลขที่ "+_vm._s(_vm.$route.params.id)+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"bg-text-card mx-auto"},[_c('p',{staticClass:"text-pink-1"},[_vm._v(" ราคาที่แทงรวม ")]),_c('p',{staticClass:"text-warning",staticStyle:{"font-weight":"600","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.Commas( _vm.ListDataHead.reduce( (acc, ele) => acc + Number(ele.amount), 0, ), ))+" ฿ ")])])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"bg-text-card mx-auto"},[_c('p',{staticClass:"text-pink-1"},[_vm._v(" ถูกรางวัลรวม ")]),_c('p',{staticClass:"text-info",staticStyle:{"font-weight":"600","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.ListDataHead.some( el => el.status_result !== 'lose' && el.status_result !== 'win', ) ? '-' : _vm.Commas( _vm.ListDataHead.reduce( (acc, ele) => acc + (ele.status_result === 'win' ? Number(ele.amount) * Number(ele.win_rate) : 0), 0, ) ))+" ฿ ")])])]),(_vm.ListDataCal.some(
              el => el.status_result === 'waiting' && el.status !== 'cancle',
            ))?_c('b-col',{attrs:{"cols":"12"}},[(_vm.MinuteRefund <= 15)?_c('button',{staticClass:"btn btn-block btn-yes-2 mx-auto mt-1 w-50",attrs:{"block":""},on:{"click":function($event){return _vm.CancelBill(_vm.$route.params.id)}}},[_vm._v(" คืนโพยหวย ")]):_vm._e()]):_vm._e()],1)],1)],1),_c('div',[_c('b-overlay',{attrs:{"show":_vm.show,"variant":"transparent","opacity":"0.99","blur":"5px","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"neonPink",attrs:{"id":"cancel-label"}},[_c('img',{attrs:{"src":"/BpLotto.png","alt":"logo","width":"100px"}}),_c('br'),_vm._v(" กรุณารอสักครู่... ")])])]},proxy:true}])},[(!_vm.show)?_c('div',{staticClass:"section-wrap block p-2"},_vm._l((_vm.ListData[0]),function(key,index){return _c('div',{key:key.index},[_c('div',{staticClass:"section-title-l"},[_c('strong',{staticClass:"text-white font-weight-bolder"},[_vm._v(" "+_vm._s(index)+" ")])]),_vm._l((key),function(item){return _c('ul',{key:item._id,staticClass:"list-group"},[_c('li',{staticClass:"row-group align-items-center bg-dark-red-1",staticStyle:{"height":"initial"}},[_c('div',{staticClass:"box-number"},[_c('strong',{staticClass:"color-green text-pink-2"},[_vm._v(" "+_vm._s(item.bet))])]),_c('div',{staticClass:"box-text-price h-space"},[_vm._v("แทง "),_c('br'),_vm._v(" จ่าย ")]),_c('div',{staticClass:"box-price text-pink-1"},[_c('strong',[_vm._v(" "+_vm._s(_vm.Commas(item.amount))+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.Commas(item.win_rate))+" ")]),_c('div',{staticClass:"box-result d-flex justify-content-between color-red"},[_c('div',{staticClass:"rs-text-left"},[_c('span',{staticStyle:{"font-size":"100%"},style:(`color: ${item.status_result === 'win'
                        ? 'green'
                        : item.status_result === 'lose'
                          ? 'red'
                          : item.status === 'cancle'
                            ? 'red'
                            : '#ffecf3'
                      }`)},[_vm._v(" "+_vm._s(item.status_result === 'win' ? 'ชนะ' : item.status_result === 'lose' ? 'แพ้' : item.status === 'cancle' ? 'ยกเลิกโพย' : 'รับแทง')+" ")])]),_c('div',{staticClass:"re-text-right"},[(item.status === 'confirm')?_c('span',{staticStyle:{"font-size":"100%"},style:(`color: ${item.status_result === 'win'
                        ? 'green'
                        : item.status_result === 'lose'
                          ? 'red'
                          : 'yellow'
                      }`)},[_vm._v(" "+_vm._s(item.status_result === 'win' || item.status_result === 'lose' ? _vm.Commas( (item.status_result === 'win' ? item.amount * item.win_rate : '-' + (item.amount)), ) : 'รอผล'))]):_c('span',{staticStyle:{"font-size":"100%"},style:(`color: red`)})])])])])})],2)}),0):_vm._e()])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt-right block mt-2"},[_c('div',{staticClass:"font-weight-bolder"},[_c('img',{attrs:{"src":"/icons/topic.png","alt":"topic","width":"20px"}}),_vm._v(" "),_c('span',{staticClass:"text-pink"},[_vm._v("โพยหวย")])])])
}]

export { render, staticRenderFns }